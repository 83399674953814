import React, { Component } from 'react'
import { graphql } from 'gatsby'
import ASKey from "../../build/contracts/ASKey.json"
import getWeb3 from "../utils/getWeb3"
import truffleContract from "truffle-contract"
import Layout from '../components/layout'
import axios from 'axios'
import axiosRetry from 'axios-retry';
import dateFormat from 'dateformat'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import styled from 'styled-components'

import ali from '../images/ali-512.png'

axiosRetry(axios, { retries: 3 })

const LoadingContainer = styled.div`
  bottom: 0;
  width: 100%;
  height: 100vh;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    max-width: 200px;
    width: 40%;
    filter: invert(100%);
    animation: blink 500ms;
    animation-iteration-count: infinite;
    animation-timing-function: step-start;
  }

  @keyframes blink {
      0% {
          opacity: 1;
      }
      50% {
          opacity: 0;
      }
      100% {
          opacity: 1;
      }
  }

  p {
    color: white;
  }
`

const ASVaultContainer = styled.div`
  font-family: Times;
  color: white;

  h3 {
    margin-bottom: 0em;
  }
`

const AccountInfo = styled.div`
  padding-top: 10px;
`

const VaultTable = styled.table`
  border-collapse: collapse;
  width: 700px;

  th, td {
  }

  td.fileDate {
    text-align: right;
    width: 1%;
    white-space: nowrap;
    padding-left: 20px !important;
  }

  td.fileSize {
    text-align: right;
    padding: 0 10px !important;
    width: 20ch;
    white-space: nowrap;
  }
`

const ASKeyItem = styled.div`
  display: inline-block;
  margin-right: 5px;

  pre {
    margin: 0.5em 0;
  }

  img {
    width: 200px;
  }
`

const AccessDenied = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  p {
    margin: 10px;
  }
`

class ASVault extends Component {
  state = {
    hasASKey: false,
    keyMetadata: [],
    web3: null,
    accounts: [],
    contract: null,
    web3LoadFailed: false,
    web3LoadSucceeded: false,
    initializingVault: true
  };

  componentDidMount = async () => {
    try {
      // Get network provider and web3 instance.
      const web3 = await getWeb3();

      // Use web3 to get the user's accounts.
      let accounts = await web3.eth.getAccounts();


      // Get the contract instance.
      const ASKeyContract = truffleContract(ASKey);
      ASKeyContract.setProvider(web3.currentProvider);
      const instance = await ASKeyContract.deployed();

      this.setState({ web3LoadSucceeded: true })


      function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }


      while (accounts.length === 0) {
        console.log("No user found, trying again shortly...")
        await sleep(2000);

        accounts = await web3.eth.getAccounts();
      }
      // Set web3, accounts, and contract to the state, and then proceed with an
      this.setState({web3, accounts, contract: instance}, async () => {

        const {contract, accounts} = this.state

        // keyBalance = number of AS Keys that this user has
        const keyBalance = (await contract.balanceOf.call(accounts[0])).toNumber();


        // Iterate through all AS Keys for this user
        // populating component state with AS Key metadata
        let tokens = Array(keyBalance)

        for (let i = 0; i < keyBalance; i++){
          const tokenId = (await contract.tokenOfOwnerByIndex.call(accounts[0],i)).toNumber();
          const tokenMetadataUrl = await contract.tokenURI.call(tokenId)
          const opensea_url =
            "https://opensea.io/assets/" + contract.address + "/" + tokenId

          const response = await axios.get(tokenMetadataUrl)

          let metadata = response.data
          metadata.opensea_url = opensea_url

          tokens[i] = metadata
        }

        this.setState({hasASKey: (keyBalance > 0), keyMetadata: tokens, initializingVault: false})

      })

    } catch (error) {
      // Catch any errors for any of the above operations.
      this.setState({web3LoadFailed: true});
    }
  }

  renderLoadingText() {
    if (this.state.web3LoadFailed) {
      return (
        <p>Please install <OutboundLink href="https://metamask.io/" target="_blank" rel="noopener noreferrer">Metamask</OutboundLink> or another valid web3 provider...</p>
      )
    } else if (this.state.web3LoadSucceeded && this.state.accounts.length == 0){
      return <p>Metamask detected, but no account found. Please log-in to metamask.</p>
    } else {
      return <p>Loading...</p>
    }
  }

  renderVaultItems = () => {
    let formatDate = function(nodeDate) {
      if(nodeDate !== "--") {
        try {
          let date = new Date(nodeDate)

          return dateFormat(date, "dd-mmm-yyyy HH:MM")
        } catch (error) {
          console.log(`Bad date: ${nodeDate}`)
        }
      }

      return "--"
    }

    return this.props.data.allVaultYaml.edges.map( ({node}, i) =>
      <tr key={i}>
        <td><OutboundLink href={ node.publicUrl } referrerPolicy="origin">{ node.fileName }</OutboundLink></td>
        <td className="fileDate">{ formatDate(node.fields.lastModified) }</td>
        <td className="fileSize">{ node.fields.contentLength }</td>
      </tr>
    )
  }

  render() {
    if (this.state.initializingVault) {
      return (
        <Layout>
          <LoadingContainer>
            <img src={ali} alt="" />
            {this.renderLoadingText()}
          </LoadingContainer>
        </Layout>
      )
    } else if (!this.state.hasASKey) {
      return (
        <Layout>
          <AccessDenied>
            <p>ACCESS DENIED</p>
            <p>NO <OutboundLink href="https://opensea.io/category/askey">AS KEY</OutboundLink> DETECTED IN WALLET</p>
          </AccessDenied>
        </Layout>
      )
    } else {
      return (
        <Layout>
        <ASVaultContainer>
          <h1>AS Vault (Beta)</h1>
          <hr/>
          <pre>
          <VaultTable>
            <tbody>
            <tr>
              <td><OutboundLink href="https://amnesiascanner.net">../</OutboundLink></td>
              <td></td>
              <td className="fileSize">--</td>
            </tr>
            {this.renderVaultItems()}
            </tbody>
          </VaultTable>
          </pre>
          <hr/>
          <AccountInfo>
            <h3>Your Address</h3>
            <pre>{this.state.accounts[0]}</pre>
            <h3>Your AS Key{(this.state.keyMetadata.length > 1 ? "s" : "")}</h3>
            <div>
              { this.state.keyMetadata.map((asKey, i) => (
                  <OutboundLink href={asKey.opensea_url} target="_blank" rel="noopener noreferrer" key={i}>
                  <ASKeyItem key={i}>
                    <pre>{asKey.name}</pre>
                    <img src={asKey.image} alt=""/>
                  </ASKeyItem>
                  </OutboundLink>
              ))}
            </div>
          </AccountInfo>
        </ASVaultContainer>
      </Layout>
      )
    }
  }
}

export default ASVault

export const query = graphql`
  query {
    allVaultYaml {
      edges {
        node {
          internal {
            type
          }
          publicUrl
          fileName
          fields {
            contentLength
            lastModified
          }
        }
      }
    }
  }
`
